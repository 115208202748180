import { formatDistanceToNowStrict } from 'date-fns';

export function formatDistanceToNowWithShortText(dateTime, option) {
  let formattedDateTime = formatDistanceToNowStrict(dateTime, option);

  formattedDateTime = formattedDateTime
    .replace(/ second(s)*/, 's')
    .replace(/ minute(s)*/, 'm')
    .replace(/ hour(s)*/, 'h')
    .replace(/ day(s)*/, 'd')
    .replace(/ month(s)*/, 'M')
    .replace(/ year(s)*/, 'Y');

  return formattedDateTime;
}
